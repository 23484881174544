
import { defineComponent, inject, onUnmounted, PropType, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { TreeOptions } from '@/types/widget.config';
import { DataItem } from '@/types/app.global';
import constants from '@/utils/constants';

export default defineComponent({
  name: 'TreeWidget',
  props: {
    opts: {
      type: Object as PropType<TreeOptions>,
      default: () => ({})
    }
  },
  setup() {
    const dataList = ref([] as DataItem[]);
    const expandedKeys = ref([] as number[]);
    const selectedKeys = ref([] as number[]);
    const checkedKeys = ref({});
    const fieldNames = { title: 'label', key: 'id', children: 'group' };

    const { bus } = useInject();
    const modalConfirm = inject<symbol>(constants.event.modalConfirm);

    // 外层模态框提交时的回调
    const onModalSubmit = (callback: unknown) => {
      if (typeof callback === 'function') {
        callback(checkedKeys.value);
      }
    };

    const setData = (data: DataItem[], keys: number[]) => {
      dataList.value = data;
      expandedKeys.value = keys;
      // selectedKeys = keys;
      checkedKeys.value = { checked: keys };
    };

    const getCheckedKeys = () => checkedKeys.value;
    const expose = { setData, getCheckedKeys };

    if (modalConfirm) {
      bus?.on(modalConfirm, onModalSubmit);
    }

    onUnmounted(() => {
      if (modalConfirm) {
        bus?.off(modalConfirm);
      }
    });
    return { dataList, expandedKeys, selectedKeys, checkedKeys, fieldNames, expose };
  }
});
