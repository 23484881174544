import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tree_widget = _resolveComponent("tree-widget")!
  const _component_modal_widget = _resolveComponent("modal-widget")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_modal_widget, { opts: _ctx.opts }, {
      content: _withCtx(() => [
        _createVNode(_component_tree_widget, { ref: "treeRef" }, null, 512)
      ]),
      _: 1
    }, 8, ["opts"])
  ]))
}