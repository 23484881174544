import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"max-height":"600px","overflow-y":"auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tree = _resolveComponent("a-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tree, {
      checkable: "",
      checkStrictly: "",
      selectable: false,
      "tree-data": _ctx.dataList,
      fieldNames: _ctx.fieldNames,
      expandedKeys: _ctx.expandedKeys,
      "onUpdate:expandedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.expandedKeys) = $event)),
      selectedKeys: _ctx.selectedKeys,
      "onUpdate:selectedKeys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event)),
      checkedKeys: _ctx.checkedKeys,
      "onUpdate:checkedKeys": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.checkedKeys) = $event))
    }, null, 8, ["tree-data", "fieldNames", "expandedKeys", "selectedKeys", "checkedKeys"])
  ]))
}