
import { defineComponent, reactive, ref } from 'vue';
import { useInject } from '@/utils/inject';
import { DataItem } from '@/types/app.global';

import RestTable from '@/components/table/RestTable.vue';
import TreeModal from '@/components/composite/TreeModal.vue';

export default defineComponent({
  name: 'Role',
  components: { TreeModal, RestTable },
  setup() {
    const { https, mapper, notify } = useInject();
    let roleId = 0;

    const authRef = ref();
    const authOpts = reactive({
      title: '角色授权',
      visible: false,
      loading: false,
      content: {},
      action: null as any
    });

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'name',
              label: '角色名',
            }
          ]
        },
        remote: {
          action: https?.sys.pageRole
        }
      },
      cols: [
        {
          field: 'name',
          label: '角色名'
        },
        {
          field: 'deptName',
          label: '部门'
        },
        {
          field: 'type',
          label: '类型',
          options: mapper?.sys.roleTypes
        },
        {
          field: 'status',
          label: '状态',
          options: mapper?.common.enableStr
        }
      ],
      schema: {
        content: {
          cols: [
            {
              field: 'name',
              label: '角色名',
              required: true
            },
            {
              field: 'deptId',
              label: '部门名称',
              type: 'select',
              mapper: ['id', 'name'],
              remote: {
                params: { status: '1' },
                action: https?.sys.listDept
              },
              fetch: true,
              required: true,
              evaluate: (data: any) => ({ deptId: data.id, deptName: data.name })
            },
            {
              field: 'type',
              label: '类型',
              type: 'select',
              options: mapper?.sys.roleTypes,
              required: true
            },
            {
              field: 'status',
              label: '状态',
              type: 'switch',
              options: mapper?.common.enableStr,
              required: true
            }
          ]
        }
      },
      builtinCmd: {
        create: { action: https?.sys.createRole },
        update: { action: https?.sys.updateRole },
        delete: { action: https?.sys.deleteRole },
        custom: [] as any[]
      }
    };

    const roleAuth = (row: DataItem) => {
      roleId = row.id;
      https?.sys.roleMenus({ roleId }).then(res => {
        if (res.respType === 'OK') {
          authOpts.visible = true;
          authRef.value?.init(res.respData.tree, res.respData.own);
        } else {
          notify?.error(res.respMesg);
        }
      });
    };

    const doAuth = (data: { checked: number[]}) => {
      https?.sys.roleAuth({ roleId, menuIds: data.checked }).then(res => {
        authOpts.loading = false;

        if (res.respType === 'OK') {
          notify?.success(res.respMesg, '角色授权成功');
          authOpts.visible = false;
        } else {
          notify?.error(res.respMesg, '角色授权失败');
        }
      });
    };

    tableOpts.builtinCmd.custom.push({ label: '授权', action: roleAuth });
    authOpts.action = doAuth;
    return { authRef, authOpts, tableOpts };
  }
});
