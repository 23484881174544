
import { defineComponent, PropType, ref, nextTick } from 'vue';
import ModalWidget from '@/components/widget/ModalWidget.vue';
import TreeWidget from '@/components/widget/TreeWidget.vue';
import { ModalOptions } from '@/types/container.config';
import { TreeExposeType } from '@/types/types.config';

export default defineComponent({
  name: 'TreeModal',
  components: { TreeWidget, ModalWidget },
  props: {
    opts: {
      type: Object as PropType<ModalOptions>,
      default: () => ({})
    }
  },
  setup() {
    const treeRef = ref<TreeExposeType>();
    const init = (data: any[], keys: any[]) => {
      nextTick(() => {
        treeRef.value?.expose.setData(data, keys);
      });
    };
    return { treeRef, init };
  }
});
