import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rest_table = _resolveComponent("rest-table")!
  const _component_tree_modal = _resolveComponent("tree-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_rest_table, { opts: _ctx.tableOpts }, null, 8, ["opts"]),
    _createVNode(_component_tree_modal, {
      ref: "authRef",
      opts: _ctx.authOpts
    }, null, 8, ["opts"])
  ]))
}